import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ResourceCard>`}</inlineCode>{` component should be wrapped with a `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` inside of `}<inlineCode parentName="p">{`<Row className="resource-card-group">`}</inlineCode>{`. This allows the correct border placement between a group of cards.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="resource-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="With subtitle" title="Title" aspectRatio="2:1" actionIcon="arrowRight" href="https://github.com/IBM/carbon-elements/blob/master/.github/CONTRIBUTING.md" mdxType="ResourceCard">
          <img {...{
            "src": "/eda41104093832b5cc8491b69afbf330/rebus.svg",
            "alt": "IBM Rebus"
          }}></img>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Only subtitle" actionIcon="download" aspectRatio="2:1" href="https://github.com/IBM/carbon-elements/blob/master/.github/CONTRIBUTING.md" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVQ4y4WUzUsWURTG5/3IRRHpO3PnOhq6CSECoZDMRdCuTUJbBWlh/gEt1NW7dOEfEOgyJCJooUJbyVWIQgVR23YatQjx21ftOXeeM5x3EBz4cWfOnHnmnOeemSh1SaQHzmvm/D6YA1tgD5yBHbACJkHD5FZldS4utCKXxDXevAOWwYVP3YWsFp8W8T9gtixaFnsODuQBxE5wLrRYnXIKjs0LP4HOQlTbpJipJIgK5zaO65atnusXcMN6J23u+/yhn2AMvDNC4uEuOGLONpgBTamaou+t4LIkMvmNiY+AJ6AXpGAAPAMZ7zfkZdIFnx3V3Qye8U0f6Ws9uuJA7j2pUGyg4JoE54JQEh9SsBmSk7gqJpOKoaY7ivUm+B5syb0+ijhn6pXM2S0mV66ors71JTewJaIRPThjyZ8lKYnjivdJZIfeHs4lkalymJbJSAXBlo4C2MwFG0HsMkHPuBF8XBbcLrXcZVu2ot65UF2p5amiZWyQBFblc0LgmKIT3OUOboB+TSokG3PNeLlOu0KXhalhaPP1UHwpbUCbqInPs91TFvNbh/MfeQF+8OYiGAdJ/nCsFgyBafHb52IyLjrDr/VNM90+lcASuA3+9mRefc1Knr1irvqmG7oP+m35a1l3EBnLfFpntYN5q8E33dVB/Tp0QljdZNt/TH5B4BtvzoJH4GEu2GXzHlDwxPxtmvS4Wha9Dj5IS6z2KxNthUNGaE8rY07bOFRN+0/BBnh7ieBd8AssgD6tTCfhP7zh+TRsafseAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Github icon",
              "title": "Github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/59afc/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/59afc/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Alternate color" title="Dark" aspectRatio="2:1" color="dark" actionIcon="email" href="https://github.com/IBM/carbon-elements/blob/master/.github/CONTRIBUTING.md" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADKklEQVQ4y4WU3UvTYRTHz17KVixKSejWq6CiuisiqLsuuguE/oAsdCsx0YS6yKiLLiKkLMWKbgzN6TZHRBcGBZnLkJwJroKQLNvmNst0L/7O6Zzz++1FI3rg4Tk75/v9PM95nm1Ag6ADg2CTNU81gBPOSnzv2IUR91FjAk5h9GATRg9f0HjKfUxrE86qHFWrBwOmF31gwYbBLis9hEocc7wxppxLGKnIUASIPm4lyvQQZR8QfdpGkpOaasYcYXoEO8oZICfE5zbdgQbAR9MOwukKNtrYuIlw/qZB6Rd5So/kcf6WgZHNqDXRsJY9AQWO2oF8JtChMB800zPePbwhZ0w5DJx0IH5rR4YhJgM8/Wb8/RripBNVw1rxsLcNyljS+yH+QDIx7DAMaetriwCIQUTJYZ0ap0e41oaqeWtHGrQRDXHsgyNmy5Pg4kSUQiCFVRzn4pwXafElYSpEmH7KEHNKLDmtzTUivjM9lvczjcMWWO2FAUkY/bYc9jNs5iTSQggp9pgw1kcU79O1PJYaawijtSge9uZpGIhZAUg2Q2e2GWjhPOQTHsBMjxsp5EIcchH5XYR+cy2Ptcaa7H03JryA4lVGM3Rr2/F6eJJrAoqdhVzqot4H8kUjDmgsl64TfcXPWk+zNnYGcuJlxhAURuoKOOMeGM1Y0MxdNgUZKEZ5KGtqLDmuZe4xRLQC80A42QYbzdN5zKeOe6GaW/7yywuUauUfjE8BCsXS6WQD3YQ7yYuWPbPM2FnOKkE9sJcFS6l6oJU7/HrBIqT8dJTphFXRsHaZPfvV2wBOsAIoTyTOwfFkA1C6VQFGAVRsn3PcAaVYw9oT5V6+R20V+GLXQ+uWuZ2V2wwMllqWWE4uNdbUr4HVqc8chSDRqLvoj5wf57pxSUF5694EnDcu68vesE5kj1teOdiaUUgw3DZfa8Y/TkMvdenJcjq7GXYa+qVm7FGP/qkk1sOKUE9xVSF/sezJFnhFfoYGgJKt8DoMVosNZieFN/jnKEItw4ca2J7phlmeczO7oWo9LNv1H2CyqbTrYrv5dfrdAfuWO+CAxD+vmrkEaxJNf/v/AIL71PhEkAqpAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Sketch icon",
              "title": "Sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/59afc/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/59afc/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard title="Disabled card" aspectRatio="2:1" disabled href="https://github.com/IBM/carbon-elements/blob/master/.github/CONTRIBUTING.md" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADKklEQVQ4y4WU3UvTYRTHz17KVixKSejWq6CiuisiqLsuuguE/oAsdCsx0YS6yKiLLiKkLMWKbgzN6TZHRBcGBZnLkJwJroKQLNvmNst0L/7O6Zzz++1FI3rg4Tk75/v9PM95nm1Ag6ADg2CTNU81gBPOSnzv2IUR91FjAk5h9GATRg9f0HjKfUxrE86qHFWrBwOmF31gwYbBLis9hEocc7wxppxLGKnIUASIPm4lyvQQZR8QfdpGkpOaasYcYXoEO8oZICfE5zbdgQbAR9MOwukKNtrYuIlw/qZB6Rd5So/kcf6WgZHNqDXRsJY9AQWO2oF8JtChMB800zPePbwhZ0w5DJx0IH5rR4YhJgM8/Wb8/RripBNVw1rxsLcNyljS+yH+QDIx7DAMaetriwCIQUTJYZ0ap0e41oaqeWtHGrQRDXHsgyNmy5Pg4kSUQiCFVRzn4pwXafElYSpEmH7KEHNKLDmtzTUivjM9lvczjcMWWO2FAUkY/bYc9jNs5iTSQggp9pgw1kcU79O1PJYaawijtSge9uZpGIhZAUg2Q2e2GWjhPOQTHsBMjxsp5EIcchH5XYR+cy2Ptcaa7H03JryA4lVGM3Rr2/F6eJJrAoqdhVzqot4H8kUjDmgsl64TfcXPWk+zNnYGcuJlxhAURuoKOOMeGM1Y0MxdNgUZKEZ5KGtqLDmuZe4xRLQC80A42QYbzdN5zKeOe6GaW/7yywuUauUfjE8BCsXS6WQD3YQ7yYuWPbPM2FnOKkE9sJcFS6l6oJU7/HrBIqT8dJTphFXRsHaZPfvV2wBOsAIoTyTOwfFkA1C6VQFGAVRsn3PcAaVYw9oT5V6+R20V+GLXQ+uWuZ2V2wwMllqWWE4uNdbUr4HVqc8chSDRqLvoj5wf57pxSUF5694EnDcu68vesE5kj1teOdiaUUgw3DZfa8Y/TkMvdenJcjq7GXYa+qVm7FGP/qkk1sOKUE9xVSF/sezJFnhFfoYGgJKt8DoMVosNZieFN/jnKEItw4ca2J7phlmeczO7oWo9LNv1H2CyqbTrYrv5dfrdAfuWO+CAxD+vmrkEaxJNf/v/AIL71PhEkAqpAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Sketch icon",
              "title": "Sketch icon",
              "src": "/static/25ef536ecb3b4de186e21f7a970c20ee/59afc/sketch-icon.png",
              "srcSet": ["/static/25ef536ecb3b4de186e21f7a970c20ee/59afc/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Group</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Row className="resource-card-group">
  <Column colMd={4} colLg={4} noGutterSm>
    <ResourceCard
      subTitle="Carbon Design System"
      href="https://www.carbondesignsystem.com"
    >
      ![Github icon](/images/github-icon.png)
    </ResourceCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    <ResourceCard
      subTitle="Carbon Design System"
      href="https://www.carbondesignsystem.com"
    >
      ![Github icon](/images/github-icon.png)
    </ResourceCard>
  </Column>
</Row>
`}</code></pre>
    <Title mdxType="Title">With title</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="With subtitle"
    title="Title"
    aspectRatio="2:1"
    actionIcon="arrowRight"
    href="https://github.com/IBM/carbon-elements/blob/master/.github/CONTRIBUTING.md"
  >
    ![Github icon](/images/github-icon.png)
  </ResourceCard>
</Column>
`}</code></pre>
    <Title mdxType="Title">Only subtitle</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="Only subtitle"
    actionIcon="download"
    aspectRatio="2:1"
    href="https://github.com/IBM/carbon-elements/blob/master/.github/CONTRIBUTING.md"
  >
    ![Github icon](/images/github-icon.png)
  </ResourceCard>
</Column>
`}</code></pre>
    <Title mdxType="Title">Dark</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="Alternate color"
    title="Dark"
    aspectRatio="2:1"
    color="dark"
    actionIcon="email"
    href="https://github.com/IBM/carbon-elements/blob/master/.github/CONTRIBUTING.md"
  >
    ![Sketch icon](/images/sketch-icon.png)
  </ResourceCard>
</Column>
`}</code></pre>
    <Title mdxType="Title">Disabled</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    title="Disabled card"
    aspectRatio="2:1"
    disabled
    href="https://github.com/IBM/carbon-elements/blob/master/.github/CONTRIBUTING.md"
  />
</Column>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use 32x32 image as child, will display in bottom left of card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Smaller title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Large title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`arrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`download`}</inlineCode>{`, `}<inlineCode parentName="td">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aspectRatio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2:1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set card aspect ratio, default is 2:1, options are 1:1, 16:9, 4:3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      